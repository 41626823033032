import React, { lazy, Suspense } from 'react';

const LazyComponents = lazy(() => import('./index'));

const Register = props => (
  <Suspense fallback={null}>
    <LazyComponents {...props} />
  </Suspense>
);

export default Register;
