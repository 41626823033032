import React, { useEffect } from "react";
import { Navigate, useLocation, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./hooks/auth";

const ProtectedRoute = ({ children }) => {

  const { isAuthed } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();
  if (!isAuthed()) {
    setTimeout(() => {
      let path = `/auth/login?redirect=${encodeURIComponent(location.pathname)}`;
      navigate(path);
    }, 500);
  }

  return <Outlet />
};

export default ProtectedRoute;