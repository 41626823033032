import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import myAccountApi from '../services/general/myAccount';

const initialState = {
    isAuthed: false,
    userAuthInfo: null,
    user: null,
    userId: null,
    avatar: null,
    teachingState: false,
    currentScreenShot: false,
    screenShotLink: null,
    currentLang: "繁體中文",
    personalSetting: null,
    learningTimes:0,
    showChangeImgAlert:false,
    showPasswordAlert:false
}

const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        setUserAuth(state, action) {
            localStorage.setItem("userAuth", JSON.stringify(action.payload));
            return { ...state, isAuthed: true, userAuthInfo: action.payload };
        },
        setUserData(state, action) {
            const userInfo = action.payload;
            userInfo.userAccount = userInfo.userName?.slice(userInfo.userName.indexOf('+') + 1)
            return { ...state, user: userInfo, avatar: userInfo.avatarUrl, userId: userInfo.id };
        },
        clearUserAuth(state, action) {
            localStorage.removeItem('userAuth');
            return initialState;
        },
        reloadUserAuth(state, action) {
            let userAuth = localStorage.getItem('userAuth');
            if (userAuth) {
                try {
                    const userAuthObj = JSON.parse(userAuth);
                    return { ...state, isAuthed: true, userAuthInfo: userAuthObj };
                } catch {
                    return { ...state, isAuthed: false, userAuthInfo: null };
                }
            }
            else
                return initialState;
            return { ...state, isAuthed: !!userAuth, userAuthInfo: userAuth };
        },
        setNotifyViewed(state, action) {
            if (action.type == "System")
                state.user.hasSystemNotify = false;
            else
                state.user.hasPersonalNotify = false;
        },
        setTeachingState(state, action) {
            return { ...state, teachingState: action.payload };
        },
        setCurrentScreenShot(state, action) {
            return { ...state, currentScreenShot: action.payload };
        },
        setScreenShotLink(state, action) {
            return { ...state, screenShotLink: action.payload };
        },
        setCurrentLanguage(state, action) {
            return { ...state, currentLang: action.payload };
        },
        setPersonalSetting(state, action) {
            return { ...state, personalSetting: action.payload };
        },
        setLearningTimes(state, action) {
            return { ...state, learningTimes: action.payload };
        },
        setShowChangeImgAlert(state, action) {
            // alert 改頭像變更成功 
            return { ...state, showChangeImgAlert: action.payload };
        },
        setShowPasswordAlert(state, action) {
            // alert 密碼變更成功 
            return { ...state, showPasswordAlert: action.payload };
        },
    }
});

export const {
    setUserAuth,
    setUserData,
    clearUserAuth,
    reloadUserAuth,
    setNotifyViewed,
    setTeachingState,
    setCurrentScreenShot,
    setScreenShotLink,
    setCurrentLanguage,
    setPersonalSetting,
    setLearningTimes,
    setShowChangeImgAlert,
    setShowPasswordAlert
} = userInfoSlice.actions;

export default userInfoSlice.reducer

