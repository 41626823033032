import React, { lazy, Suspense } from 'react';

const LazyResetmypassword = lazy(() => import('./Resetmypassword'));

const Resetmypassword = props => (
  <Suspense fallback={null}>
    <LazyResetmypassword {...props} />
  </Suspense>
);

export default Resetmypassword;
