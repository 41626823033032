import api, { handleTokenExpiration } from "../api"

const login = async (enterpriseId, username, password) => {
    let postData = {
        enterpriseId: enterpriseId,
        userName: username,
        password: password,
        platform: process.env.REACT_APP_PLATFORM
    };
    return await api.post("/Auth/Login", postData);
};

const tokenLogin = async (token) => {
    let postData = {
        token: token,
        platform: process.env.REACT_APP_PLATFORM
    };
    return await api.post("/Auth/OneTimeToken", postData);
};

const refreshToken = async () => {
    await handleTokenExpiration();
};

export default { login, tokenLogin, refreshToken };