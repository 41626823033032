import _axios from "axios"
import { setUserAuth, clearUserAuth } from '../reducers/user';

let dispatchFunction;
let isRefreshing = false;

const initApi = (dispatch) => {
    dispatchFunction = dispatch;
};
const getUserAuth = () => {
    const userAuth = localStorage.getItem('userAuth');
    if (!userAuth)
        throw new Error('Failed to get token');

    return JSON.parse(userAuth);
}


const handleTokenExpiration = async () => {
    try {
        isRefreshing = true;
        const userAuth = getUserAuth();
        const refreshToken = userAuth.refreshToken;
        const url = `${process.env.REACT_APP_TAGEASY_API_URL}Auth/RefreshToken`;
        const refreshResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken, platform: process.env.REACT_APP_PLATFORM }),
        });

        if (!refreshResponse.ok) {
            throw new Error('Token refresh failed');
        }

        const refreshedData = await refreshResponse.json();

        dispatchFunction(setUserAuth(refreshedData.data));

    } catch (error) {
        dispatchFunction(clearUserAuth());
        throw new Error('Failed to refresh token');
    }
    finally {
        isRefreshing = false;
    }
};


const waitForRefreshComplete = () => {
    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (!isRefreshing) {
                clearInterval(interval);
                resolve();
            }
        }, 200);
    });
}

const axios = (baseURL) => {

    const sleep = async (ms = 0) => {
        return new Promise(r => setTimeout(r, ms));
    }

    //建立一個自定義的axios
    const instance = _axios.create({
        baseURL: baseURL || process.env.REACT_APP_TAGEASY_API_URL, //JSON-Server端口位置
        timeout: 60000,
    });


    const clearAuth = () => {
        window.localStorage.removeItem('userAuth');
    }

    const exclude = [
        "/Auth/Login",
        "/Auth/OneTimeToken",
        "/Auth/RefreshToken",
        "/MyAccount/PasswordResetApply",
        "/MyAccount/PasswordResetConfirm",
        "/UserRegister/EmailInvite",
        "/UserRegister",
        "/UserRegister/EamilResend",
        "/UserRegister/EmailConfirm",
        "/Explore"
    ];

    const isSkipAuthToken = (request) => {
        for (let targetUrl of exclude) {
            if (request.url.indexOf(targetUrl) >= 0) return true;
        }
        return false;
    }

    instance.interceptors.request.use(
        async request => {
            if (!isSkipAuthToken(request)) {
                let userAuthValue = window.localStorage.getItem('userAuth');
                if (userAuthValue) {
                    const userAuth = JSON.parse(userAuthValue);
                    request.headers = {
                        'Authorization': `Bearer ${userAuth.accessToken}`,
                        'Accept': 'application/json'
                    }
                }
            }
            return request;
        },
        error => {
            Promise.reject(error);
        });

    // Response interceptor for API calls
    instance.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        const originalRequest = error.config;
        if (error.response?.status === 401) {
            if (error.response.data.Message === "Token Expired" && !originalRequest._retry) {
                originalRequest._retry = true;
                if (!isRefreshing) {
                    await handleTokenExpiration();
                }
                else {
                    await waitForRefreshComplete();
                }
                return instance(originalRequest);
            }
            else {
                clearAuth();
            }
        }
        return Promise.reject(error);
    });

    return instance;
};


export { axios, initApi, handleTokenExpiration };
export default axios();