import api from "../api"
const forgetpassword = async (username,enterpriseId) => {
    let postData = {
        userName: username,
        enterpriseId:enterpriseId
    };
    return await api.post("/MyAccount/PasswordResetApply", postData)
}


const resetpassword = async (username,password,resetToken) => {
    const data = {
        username,
        password,
        resetToken
    };
    return await api.post("/MyAccount/PasswordResetConfirm",data)
}

const getUserInfo = async () => {
    return await api.get(`MyAccount/UserInfo`);
}

const getPersonalSetting = async () => {
    return await api.get(`MyAccount/PersonalSetting`);
}

const changepassword = async (oldpassword,newpassword) => {
    const data = {
        oldpassword : oldpassword,
        newpassword : newpassword
    };
    return await api.post("/MyAccount/ChangePassword",data)

}

const changename = async (name, enableFirstTutor) => {
    const data = {
        name : name,
        enableFirstTutor : enableFirstTutor
    }
    return await api.patch(`MyAccount/UserInfo`,data);
};

const changePersonalSetting = async (data) => {
    return await api.patch(`MyAccount/PersonalSetting`,data);
};

const changeImage = async (formData) => {
    return await api.post("/MyAccount/Avatar", formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
};

const changeEmail = async (email) => {
    const data = {
        email : email,
    }
    return await api.patch(`MyAccount/UserInfo`,data);
};

export default {
    forgetpassword,
    resetpassword,
    getUserInfo,
    getPersonalSetting,
    changepassword,
    changename,
    changeImage,
    changePersonalSetting,
    changeEmail
};