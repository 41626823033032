import { useState, useEffect, useRef, useContext, createContext, } from 'react';

const UserMediaContext = createContext();

export const useUserMedia = () => useContext(UserMediaContext);
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
export const UserMediaProvider = ({ children }) => {
    const [audioInputDevices, setAudioInputDevices] = useState([]);
    const [selectedAudioInputDeviceId, setSelectedAudioInputDeviceId] = useState('');
    const [audioOutputDevices, setAudioOutputDevices] = useState([]);
    const [selectedAudioOutputDeviceId, setSelectedAudioOutputDeviceId] = useState('');
    const [audioInputStream, setAudioInputStream] = useState();
    const [recordBlob, setRecordBlob] = useState(null);
    const [recorder, setRecorder] = useState(null);
    const countdownTimer = useRef(null);
    const myAudio = useRef(null);
    const [permissionStatus, setPermissionStatus] = useState(null);
    const [testDone, setTestDone] = useState(false);
    const prevAudioInputStream = usePrevious(audioInputStream);

    let chunks = [];

    const getPermissionsStatus = () => {

        if (navigator.permissions && navigator.permissions.query) {
            // 麥克風權限參數 : prompt:詢問、granted:允許、denied:封鎖
            navigator.permissions.query({ name: 'microphone' })
                .then(permissionStatus => {
                    setPermissionStatus(permissionStatus.state);
                    permissionStatus.onchange = () => {
                        setPermissionStatus(permissionStatus.state);
                    };
                })
                .catch(error => {
                    console.error('獲取麥克風權限狀態時發生錯誤:', error);
                });
        } else if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            // 在不支援 navigator.permissions.query 的瀏覽器上，嘗試訪問麥克風
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    // 麥克風可用，設定權限狀態為 'granted'
                    setPermissionStatus('granted');
                    // 確保停止訪問麥克風
                    stream.getTracks().forEach(track => track.stop());
                })
                .catch(error => {
                    // 麥克風不可用，設定權限狀態為 'denied'
                    setPermissionStatus('denied');
                });
        } else {
            // 瀏覽器不支援 navigator.permissions.query 和 navigator.mediaDevices.getUserMedia
            console.error('瀏覽器不支援獲取麥克風權限狀態');
        }
    }

    const getAudioInputDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
        setAudioInputDevices(audioInputDevices);
        // 如果 selectedAudioInputDeviceId 已有值，則不進行設定
        if (selectedAudioInputDeviceId) {
            return;
        }

        // 如果有預設裝置，設定 selectedAudioInputDeviceId 為預設裝置的 ID
        const defaultDevice = audioInputDevices.find(device => device.deviceId === 'default');
        if (defaultDevice) {
            setSelectedAudioInputDeviceId(defaultDevice.deviceId);
        } else if (audioInputDevices.length > 0) {
            // 如果沒有預設裝置，但有任何裝置，設定 selectedAudioInputDeviceId 為裝置清單的第一個裝置
            setSelectedAudioInputDeviceId(audioInputDevices[0].deviceId);
        }
    };

    const getAudioOutputDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
        setAudioOutputDevices(audioOutputDevices);
    };

    // 若錄音裝置改變時，重新抓取裝置內容
    const handleDeviceChange = () => {
        getAudioInputDevices();
    };


    useEffect(() => {

        navigator.mediaDevices.addEventListener('devicechange', handleDeviceChange);
        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', handleDeviceChange);
        };
    }, []);


    useEffect(() => {

        getAudioInputDevices();
        getAudioOutputDevices();

        myAudio.current = new Audio();
        return () => {
            myAudio.current.src = "";
            myAudio.current = null;
        };
    }, []);


    // 使用者點選權限允許時，重新載入資料。
    useEffect(() => {
        if (permissionStatus === "granted") {
            getAudioInputDevices();
            getAudioOutputDevices();

            myAudio.current = new Audio();
            return () => {
                myAudio.current.src = "";
                myAudio.current = null;
            };
        }
    }, [permissionStatus]);


    const setSelectedInputDevice = deviceId => {
        setSelectedAudioInputDeviceId(deviceId);
    };


    const setSelectedOutputDevice = deviceId => {
        setSelectedAudioOutputDeviceId(deviceId);
    };

    const startRecording = async ({ constraints, countdown = 0 }) => {
        try {
            if (!constraints) {
                if (selectedAudioInputDeviceId) {
                    constraints = {
                        audio: {
                            deviceId: selectedAudioInputDeviceId ? { exact: selectedAudioInputDeviceId } : undefined,
                        },
                    };
                }
                else {
                    constraints = {
                        audio: true
                    };
                }
            }
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            setAudioInputStream(stream);
            let mimeType = "audio/webm";
            if (MediaRecorder.isTypeSupported('audio/webm')) {
                mimeType = 'audio/webm';
            } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
                mimeType = 'audio/mp4'
            } else {
                console.error("no suitable mimetype found for this device");
            }


            countdownTimer.current = setTimeout(() => {

                const options = {
                    mimeType: mimeType
                };
                const mediaRecorder = new MediaRecorder(stream, options);
                mediaRecorder.onstop = function (e) {
                    let blob = new Blob(chunks, { 'type': mimeType });
                    chunks = [];
                    setRecordBlob(blob);
                }

                mediaRecorder.ondataavailable = function (e) {
                    chunks.push(e.data);
                }
                mediaRecorder.start(3000);
                setRecorder(mediaRecorder);
            }, countdown * 1000);

        } catch (error) {
            console.error('麥克風存取失敗', error);
        }
    };

    const stopRecording = () => {
        if (countdownTimer.current) {
            clearTimeout(countdownTimer.current);
        }
        if (recorder && recorder.state === 'recording') {
            recorder.stop();
        }
        setAudioInputStream(null);
    };

    const resetRecord = () => {
        if (audioInputStream) {
            audioInputStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        setAudioInputStream(null);
        setRecordBlob(null);
    }

    const playSound = (audioUrl) => {
        myAudio.current.pause();

        if (selectedAudioOutputDeviceId && selectedAudioOutputDeviceId.length > 0) {
            myAudio.current.setSinkId(selectedAudioOutputDeviceId)
        }
        myAudio.current.src = audioUrl;
        myAudio.current.play();
    }
    const pauseSound = () => {
        myAudio.current.pause();
    }

    useEffect(() => {
        if (prevAudioInputStream && audioInputStream !== prevAudioInputStream) {
            prevAudioInputStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
    }, [audioInputStream, prevAudioInputStream]);

    return (
        <UserMediaContext.Provider value={{
            recordBlob,
            audioInputStream,
            audioInputDevices,
            audioOutputDevices,
            selectedAudioInputDeviceId,
            setSelectedInputDevice,
            selectedAudioOutputDeviceId,
            setSelectedOutputDevice,
            resetRecord,
            startRecording,
            stopRecording,
            playSound,
            pauseSound,
            permissionStatus,
            getPermissionsStatus,
            testDone,
            setTestDone
        }}>
            {children}
        </UserMediaContext.Provider>
    );
};

export default useUserMedia;