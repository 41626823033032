import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    infos: {},
    abilityReports: {},
    sprints: {},
    sprintItemAnimation:false,
    settingSprintPlan:false,
    audioOnEndedQuestions:[],
    resetSprintAnimtion:false,
    settingSprintPlan:false
}

const userExamPackageSlice = createSlice({
    name: "examPackages",
    initialState,
    reducers: {
        setInfo(state, action) {
            const { id, data } = action.payload;
            state.infos[id] = data;
        },
        setAbilityReport(state, action) {
            const { id, data } = action.payload;
            state.abilityReports[id] = data;
        },
        setSprint(state, action) {
            const { id, data } = action.payload;
            state.sprints[id] = data;
        },
        setSprintItemAnimation(state, action){
            state.sprintItemAnimation = action.payload;
        },
        setResetSprintAnimtion(state, action){
            state.resetSprintAnimtion = action.payload;
        },
        setSettingSprintPlan(state, action) {
            const settingSprintPlan = action.payload;
            state.settingSprintPlan = settingSprintPlan;
        },
        setAudioOnEndedQuestions(state, action) {
            const audioOnEndedQuestions = action.payload;
            state.audioOnEndedQuestions = [...state.audioOnEndedQuestions, audioOnEndedQuestions];
        },
        reset: () => initialState,
    }
});

export const {
    setInfo,
    setAbilityReport,
    setSprintItemAnimation,
    setResetSprintAnimtion,
    setSprint,
    setSettingSprintPlan,
    setAudioOnEndedQuestions,
    reset
} = userExamPackageSlice.actions;

export default userExamPackageSlice.reducer

