import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDone: false,
  currentIndex: null,
  displayNo: null,
  roundnum: null,
  activityType: null,
  displayModes: ["Ask", "Result"],
  submitPool: [],
  countdown: null,
  status: null,
  isTimerPause: true,
  audioPlayOnce: false,
  remainingTime: null
};

const examInfoSlice = createSlice({
  name: 'examInfo',
  initialState: initialState,
  reducers: {
    initExamInfo(state, action) {
      return { ...initialState };
    },
    setExamInfo(state, action) {
      return { ...state, ...action.payload };
    },
    setIsDone(state, action) {
      return { ...state, isDone: action.payload };
    },
    setActivityType(state, action) {
      return { ...state, activityType: action.payload };
    },
    setTimer(state, action) {
      return { ...state, countdown: action.payload };
    },
    setRoundnum(state, action) {
      return { ...state, roundnum: action.payload };
    },
    setDisplayNo(state, action) {
      return { ...state, displayNo: action.payload };
    },
    jumpToQuestion(state, action) {
      return { ...state, currentIndex: action.payload };
    },
    pushSubmits(state, action) {
      return { ...state, submitPool: [...state.submitPool, ...action.payload] };
    },
    removeSubmit(state, action) {
      return { ...state, submitPool: state.submitPool.filter(q => q !== action.payload) };
    },
    setFinished(state, action) {
      return { ...state, status: "Finished", activityType: "Finished" };
    },
    setPause(state, action) {
      return { ...state, status: "Pause" };
    },
    pauseTimer(state, action) {
      let isTimerPause = action.payload ?? false;
      return { ...state, isTimerPause: isTimerPause };
    },
    setAudioPlayOnce(state, action) {
      return { ...state, audioPlayOnce: action.payload };
    },
    setRemainingTime(state, action) {
      return { ...state, remainingTime: action.payload };
    },
    clearExamInfo() {
      return initialState;
    }
  }
});

export const {
  initExamInfo,
  setExamInfo,
  jumpToQuestion,
  pushSubmits,
  removeSubmit,
  setActivityType,
  setIsDone,
  setFinished,
  setTimer,
  setRoundnum,
  setDisplayNo,
  pauseTimer,
  setAudioPlayOnce,
  setRemainingTime,
  clearExamInfo,
  setPause
} = examInfoSlice.actions;

export default examInfoSlice.reducer;