import { configureStore } from '@reduxjs/toolkit'
import user from './reducers/user'
import examActivityReducer from './reducers/examActivity'
import userExamPackageReducer from './reducers/userExamPackage'

const store = configureStore({
    reducer: {
        examActivity: examActivityReducer,
        userExamPackage: userExamPackageReducer,
        user: user
    }
})

export default store