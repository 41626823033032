import style from "./App.module.scss";
import { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Provider } from 'react-redux';
import store from "./store";
import useGtmTracker from './hooks/gtmTracker';
import { ThemeProvider, createTheme } from '@mui/material';
import ProtectedRoute from "./ProtectedRoute"
import { AuthProvider } from "./hooks/auth"
import { initApi } from "./services/api";
import ExamActivity from "./components/ExamActivity/index.lazy";
import Homepage from "./components/Homepage/index.lazy";
import Profile from "./components/Profile/index.lazy";
import BarExam from "./components/BarExam/index.lazy";
import Resetmypassword from "./components/Resetmypassword/Resetmypassword.lazy"
import Pages from "./components/Pages/index.lazy";
import ToCRegister from "./components/ToCRegister/index.lazy";
import ToCForgetResetSelector from "./components/ToCForgetPassword/index.lazy";
import HomeWrap from "./components/HomeWrap/index.lazy";
import ExamPackgeInfoPage from "./components/ExamPackgeInfoPage/index.lazy";
import ExamPackageSummaryPage from "./components/ExamPackageSummaryPage/index.lazy";
import StartExam from "./components/StartExam/index.lazy"
import TokenLogin from "./components/TokenLogin/index.lazy"
import ErrorPage from "./components/ErrorPage/index.lazy";
import Notification from "./components/Notification/index.lazy";
import PersonalOverview from "./components/PersonalOverview/index.lazy";
import ExamPackageListPage from "./components/ExamPackageListPage/index.lazy";
import ErrorList from "./components/ErrorList/index.lazy";
import History from "./components/History/index.lazy";
import Support from "./components/Support/index.lazy";
import KnowledgePointList from "./components/KnowledgePointList/index.lazy";
import KnowledgePointRecord from "./components/KnowledgePointRecord/index.lazy";
import MainPage from "./components/MainPage/index.lazy";
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import SimulatorPaperList from "./components/SimulatorPaperList/index.lazy";
import SimulatorExamActivity from "./components/SimulatorExamActivity/index.lazy";
import SimulatorRecordList from "./components/SimulatorRecordList/index.lazy";
import SprintDonePage from "./components/SprintDonePage/index.lazy";
import BeginnerTeachingPage from "./components/BeginnerTeachingPage/index.lazy"
import { UserMediaProvider } from "./hooks/useUserMedia";
import ChatBot from './components/ChatBot/index.lazy';
import FAQ from './components/FAQ/index.lazy';
import AIAider from './components/AIAider/index.lazy';
import PersonalSet from './components/PersonalSet/index.lazy';
import Language from './components/Language/index.lazy';
import MessageNotify from './components/MessageNotify/index.lazy';





const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#5bb6ff',
        contrastText: '#fff',
      },
      secondary: {
        main: '#f44336',
        contrastText: '#fff',
      },
      error: {
        main: '#ff6666',
        contrastText: '#fff',
      },
      warning: {
        main: '#ff9900',
        contrastText: '#fff',
      },
      info: {
        main: '#5bb6ff',
        contrastText: '#fff',
      },
      success: {
        main: '#62af00',
        contrastText: '#fff',
      }
    }
  }
);
function App() {
  useGtmTracker();

  useEffect(() => {
    initApi(store.dispatch);
  }, [store.dispatch]);


  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <UserMediaProvider>
              {process.env.REACT_APP_ENV_NAME === "dev" && 
                <>
                  <div className={style.dev_banner_topLeft}>DEV</div>
                  <div className={style.dev_banner_bottomLeft}>DEV</div>
                  <div className={style.dev_banner_topRight}>DEV</div>
                  <div className={style.dev_banner_bottomRight}>DEV</div>
                </>
              }
              <Routes>
                <Route path="" element={
                  <HomeWrap />
                }>
                  <Route path="*" element={<ErrorPage />} />
                  <Route path="/" element={<Homepage />} />
                  <Route path="/auth/:mode" element={<ToCRegister />} />
                  <Route path="/forgetmypassword" element={<ToCForgetResetSelector />} />
                  <Route path="/barexam" element={<BarExam />} />
                  <Route path="/introduction/:pages" element={<Pages />} />

                  {/* 需登入才可使用頁面 */}
                  <Route path="" element={<ProtectedRoute />}>
                    <Route path="/examactivity/:recordId" name="examactivity" element={<ExamActivity />} />
                    <Route path="/main" element={<MainPage />} >
                      <Route path="personal" element={<PersonalOverview />} />
                      <Route path="exampackages" element={<ExamPackageListPage />} />
                      <Route path="exampackages/:packageId" element={<ExamPackgeInfoPage />} />
                      <Route path="exampackages/:packageId/Summary" element={<ExamPackageSummaryPage />} />
                      <Route path="errorList" element={<ErrorList />} />
                      <Route path="history" element={<History />} />
                      <Route path="support" element={<Support />} />
                      <Route path="beginnerTeaching" element={<BeginnerTeachingPage />} />
                      <Route path="chatBot" element={<ChatBot open={true} screenType={"page"}/>} />
                      {/* <Route path="FAQ" element={<FAQ />} /> */}
                      <Route path="aiAider" element={<AIAider />} />    
                      <Route path="personalSet" element={<PersonalSet />} />    
                      <Route path="language" element={<Language />} />    
                      <Route path="messageNotify" element={<MessageNotify  />} />  
                      <Route path="notification" element={<Notification />} />
                    </Route>
                    <Route path="sprint/:sprintId" element={<SprintDonePage />}></Route>
                    <Route path="knowledgepoints" element={<KnowledgePointList />}></Route>
                    <Route path="knowledgepoint/:knowledgeId" element={<KnowledgePointRecord />}></Route>
                    {/* <Route path="knowledgepoint/:knowledgeId/Questions" element={<KnowledgeQuestions />}></Route> */}
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/resetmypassword" element={<Resetmypassword />} />
                    <Route path="/startexam/:subjectId" element={<StartExam />} />
                    <Route path="/simulator/:simulatorId/records" element={<SimulatorRecordList />} />
                    <Route path="/simulatoractivity/:recordId" name="simulatoractivity" element={<SimulatorExamActivity />} />
                  </Route>
                </Route>
                <Route path="/auth/tokenlogin" element={<TokenLogin />} />
              </Routes>
            </UserMediaProvider>
          </AuthProvider>
        </ThemeProvider>

      </Provider>
    </I18nextProvider>
  );
}




export default App;
